exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partner-resources-client-setup-js": () => import("./../../../src/pages/partner-resources/client-setup.js" /* webpackChunkName: "component---src-pages-partner-resources-client-setup-js" */),
  "component---src-pages-partner-resources-code-of-conduct-js": () => import("./../../../src/pages/partner-resources/code-of-conduct.js" /* webpackChunkName: "component---src-pages-partner-resources-code-of-conduct-js" */),
  "component---src-pages-partner-resources-index-js": () => import("./../../../src/pages/partner-resources/index.js" /* webpackChunkName: "component---src-pages-partner-resources-index-js" */),
  "component---src-pages-partner-resources-marketing-js": () => import("./../../../src/pages/partner-resources/marketing.js" /* webpackChunkName: "component---src-pages-partner-resources-marketing-js" */),
  "component---src-pages-partner-resources-plan-changes-js": () => import("./../../../src/pages/partner-resources/plan-changes.js" /* webpackChunkName: "component---src-pages-partner-resources-plan-changes-js" */)
}

